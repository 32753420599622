import React from "react"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <section class="hero">
      <div class="hero-overlay">
        <div class="container hero-text">
          <h1>Tatsu
            <br /> 
            Johnson
          </h1>
          <p>I am a graphic designer who loves all things web. Likes finding creative and effecient solutions to design related problems.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
